@import '~app/mixins';

.viewer {
  position: relative;
  overflow: hidden;
  border: 3px inset rgba(128, 128, 128, 1);
  width: 100%;

  * {
    box-sizing: border-box;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  pointer-events: none;
  // z-index: 100;

  .top,
  .bottom,
  .left,
  .right {
    background-color: rgba(80, 80, 80, 0.95);
  }
}

.frameContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.left,
.right {
  width: 100%;
}

.middle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.frame {
  position: relative;
  border: 2px solid red;

  .frameContainer {
    height: 100%;
  }

  .left,
  .right {
    min-width: unset;
  }

  .middle {
    height: 100%;
  }

  .top,
  .bottom,
  .left,
  .right {
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.bleed {
  border: 1px dashed rgba(0, 0, 0, 0.5);
}

.viewportContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  // z-index: 99;

  .viewport {
    position: relative;

    .elementContainer {
      transform-origin: top left;

      .element {
        position: absolute;
        overflow: hidden;

        &.selected {
          pointer-events: none;
        }

        .content {
          width: 100%;
          height: 100%;
          pointer-events: none;

          // CSS defaults must also be applied to Froala's config, as well as Flying Saucer's config on server.
          font-family: AvenirNext;
          font-size: 32px;
          text-align: left;

          p {
            margin: 0;
          }
        }
      }
    }
  }
}

.viewMode {
  overflow: hidden;
  pointer-events: none;
  border-style: none;

  .overlay,
  .top,
  .bottom,
  .left,
  .right {
    display: none;
  }
}

.selection {
  position: absolute;
  cursor: move;
  // z-index: 101;
  border: 1px dotted blue;
  pointer-events: all;

  .anchors {
    position: relative;
    width: 100%;
    height: 100%;

    .anchor {
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: blue;

      &.topLeft {
        top: 0;
        left: 0;
        cursor: nwse-resize;
        transform: translateX(-3px) translateY(-3px);
      }

      &.top {
        top: 0;
        left: 50%;
        cursor: ns-resize;
        transform: translateX(-2px) translateY(-3px);
      }

      &.topRight {
        top: 0;
        right: 0;
        cursor: nesw-resize;
        transform: translateX(3px) translateY(-3px);
      }

      &.bottomLeft {
        bottom: 0;
        left: 0;
        cursor: nesw-resize;
        transform: translateX(-3px) translateY(3px);
      }

      &.bottom {
        bottom: 0;
        left: 50%;
        cursor: ns-resize;
        transform: translateX(-2px) translateY(3px);
      }

      &.bottomRight {
        bottom: 0;
        right: 0;
        cursor: nwse-resize;
        transform: translateX(3px) translateY(3px);
      }

      &.left {
        top: 50%;
        left: 0;
        cursor: ew-resize;
        transform: translateX(-3px) translateY(-2px);
      }

      &.right {
        top: 50%;
        right: 0;
        cursor: ew-resize;
        transform: translateX(3px) translateY(-2px);
      }
    }
  }
}
